import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import { OrgId, CODE_TYPES } from "@ollie-sports/models";
import { shouldSendEmail } from "../../utils";
import { translate } from "@ollie-sports/i18n";
import { teams__server__getInviteCode } from "../teams.api";
import moment from "moment";

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function miscEmails__server__emailOrgTeamLinksCSVToSelf(p: {
  selfAccountId: string;
  orgId: OrgId;
  linkBase: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const {
    injectedServerLibraries: { sendGrid }
  } = getServerHelpers();

  const [account, org, orgTeamsData, accountPrivate] = await Promise.all([
    h.Account.getDoc(p.selfAccountId),
    h.Org.getDoc(p.orgId),
    h.Team.query({
      where: [
        {
          orgId: ["==", p.orgId]
        }
      ]
    }),
    h.AccountPrivate.getDoc(p.selfAccountId)
  ]);

  if (!account) {
    throw new Error("Invalid selfAccountId supplied!");
  }

  if (!org) {
    throw new Error("Invalid orgId supplied!");
  }
  const locale = accountPrivate?.communicationLocale ?? "en-us";

  const orgTeams = orgTeamsData.docs;

  const teamCodeData = await Promise.all(
    orgTeams.map(team => {
      return teams__server__getInviteCode({
        teamId: team.id,
        type: CODE_TYPES.joinTeam
      });
    })
  );

  const subject = translate({ defaultMessage: "Team Invites Export", serverLocale: locale });

  let exportString = `Team Name,Team Link,Team Code,Expiration\n`;

  teamCodeData.forEach(teamCode => {
    const team = orgTeams.find(t => t.id === teamCode.teamId);
    const formattedCodeString = `${teamCode.code.substr(0, 3)}-${teamCode.code.substr(3, 3)}-${teamCode.code.substr(6, 3)}`;
    exportString += `${team?.name ?? ""},${`${p.linkBase}/${formattedCodeString}`},${formattedCodeString},${moment(
      teamCode.expiryDateMS
    ).format("MM/DD/YYYY")}\n`;
  });

  if (shouldSendEmail(account.email)) {
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: account.email,
      subject: subject,
      ipPoolName: "Transactional",
      html: `
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8">
    <title>CSV Export</title>
    <meta name="description" content="${subject}">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    </head>
    <body>
    <p>${translate({
      defaultMessage: "Attached to this email you will find your exported team links.",
      serverLocale: locale
    })}</p>

    </body>
    </html>
    `,
      attachments: [
        {
          content: Buffer.from(exportString).toString("base64"),
          filename: `${translate({ defaultMessage: "Team Invite Links", serverLocale: locale })}.csv`,
          type: "application/csv",
          disposition: "attachment",
          contentId: "players"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${account.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }
  // SERVER_ONLY_TOGGLE
}

miscEmails__server__emailOrgTeamLinksCSVToSelf.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};

// i18n certified - complete
