import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import { Request } from "express";
import * as _ from "lodash";
import { Account } from "@ollie-sports/models";
import { translateServer } from "../../utils/serverTranslate";

type nextStepData = {
  actionTitle: string;
  actionBody: string;
  actionButtonText: string;
  actionButtonURL: string;
};

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function miscEmails__server__sendOnboardingInteraction(p: {
  data: { accountId: string; sequenceNumber: 1 | 2 | 3 | 4 };
}) {
  // SERVER_ONLY_TOGGLE
  if (!p.data.accountId) {
    throw new Error("Must pass accountId in webhook data");
  }

  if (!p.data.sequenceNumber) {
    throw new Error("Must pass sequenceNumber in webhook data!");
  }

  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const { emailTemplateServiceFn } = getServerHelpers();

  const [account, accountPrivate, userTeamsResp] = await Promise.all([
    h.Account.getDoc(p.data.accountId),
    h.AccountPrivate.getDoc(p.data.accountId),
    h.Team.query({ where: [{ accounts: { [p.data.accountId]: { exists: ["==", true] } } }] })
  ]);

  if (!account || !accountPrivate) {
    throw new Error("Invalid accountId supplied!");
  }

  const teammateIds = _.uniq(_.flatten(userTeamsResp?.docs.map(t => Object.keys(t.accounts))).filter(id => id !== account.id));
  const teammatesProm = Promise.all(teammateIds.map(id => h.Account.getDoc(id)));

  const userTeams = userTeamsResp?.docs || [];

  let numCompletedGames = 0;
  if (userTeams.length) {
    const completedGamesProm = h.SoccerGame.query({
      where: [{ teamId: ["in", userTeams.map(t => t.id)] as any }, { officialEndOfGameMS: [">", 0] }],
      limit: 5
    });
    numCompletedGames = (await completedGamesProm).docs.length;
  }

  const teammates = (await teammatesProm).filter((a): a is Account => !!a);

  const isManagingStaff = !!userTeamsResp?.docs.some(
    t => t.accounts[account.id]?.additionalPermissions?.manageRolesAndPermissions
  );

  const hasTeammates = !!teammateIds.length;
  const allTeamAccounts = teammates.concat(account);
  const trainedAccounts = allTeamAccounts.filter(a => a?.achievements?.statsTrained);
  const someoneIsTrainedOnTeam = !!trainedAccounts.length;
  const thisUserIsStatsTrained = !!account.achievements?.statsTrained;
  const areTrainedTeammatesPermittedToRecord = !!trainedAccounts.find(a =>
    userTeams.find(t => t.accounts[a.id]?.additionalPermissions?.recordStats)
  );
  const atLeastOneGameRecorded = numCompletedGames > 0;
  const multipleGamesRecorded = numCompletedGames > 1;
  const isSecondOnboardingInteractionOrEarlier = p.data.sequenceNumber <= 2;

  let nextStep:
    | "invite-parents"
    | "invite-recorders"
    | "give-trained-users-record-permission"
    | "record-stats"
    | "record-more-stats"
    | "get-trained"
    | "encourage-stat-takers"
    | "encourage-stat-takers-more"
    | "encourage-coach-to-invite-stat-takers"
    | "all-done";

  if (isManagingStaff) {
    if (!hasTeammates) {
      nextStep = "invite-parents";
    } else if (!someoneIsTrainedOnTeam) {
      nextStep = "invite-recorders";
    } else if (!areTrainedTeammatesPermittedToRecord) {
      nextStep = "give-trained-users-record-permission";
    } else if (!atLeastOneGameRecorded) {
      if (thisUserIsStatsTrained) {
        nextStep = "record-stats";
      } else {
        nextStep = "encourage-stat-takers";
      }
    } else if (!multipleGamesRecorded) {
      if (thisUserIsStatsTrained) {
        nextStep = "record-more-stats";
      } else {
        nextStep = "encourage-stat-takers-more";
      }
    } else {
      nextStep = "all-done";
    }
  } else {
    if (!thisUserIsStatsTrained && isSecondOnboardingInteractionOrEarlier) {
      nextStep = "get-trained";
    } else if (!thisUserIsStatsTrained) {
      if (someoneIsTrainedOnTeam) {
        if (!atLeastOneGameRecorded) {
          nextStep = "encourage-stat-takers";
        } else if (!multipleGamesRecorded) {
          nextStep = "encourage-stat-takers-more";
        } else {
          nextStep = "encourage-coach-to-invite-stat-takers";
        }
      } else {
        nextStep = "encourage-coach-to-invite-stat-takers";
      }
    } else {
      if (!atLeastOneGameRecorded) {
        nextStep = "record-stats";
      } else if (!multipleGamesRecorded) {
        nextStep = "record-more-stats";
      } else {
        nextStep = "all-done";
      }
    }
  }

  const nextStepMap: Record<typeof nextStep, nextStepData> = {
    "invite-parents": {
      actionTitle: translateServer({
        defaultMessage: "Invite the Team",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: translateServer({
        defaultMessage:
          'Now that your team is setup on Ollie, follow these simple steps to invite your team: 1. Go to the Teams tab in the Ollie app and select your team. 2.Click "Invite Others" and then click the green button to copy the code. 3. Send the code to your team.',
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    },
    "invite-recorders": {
      actionTitle: translateServer({
        defaultMessage: "Find Stat Takers",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: translateServer({
        defaultMessage:
          "It's time to find some people to learn to take stats for your team. Send them a chat message in Ollie and invite them to take the stats tutorial in the Stats tab of the app. It takes less than 10 minutes to learn!",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    },
    "give-trained-users-record-permission": {
      actionTitle: translateServer({
        defaultMessage: "Give Stat Takers Permission",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: translateServer({
        defaultMessage: `Give your stat takers permission to record stats by going to the Teams tab, selecting a person, selecting "Edit", and then enabling the "Record Stats" permission.`,
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    },
    "record-stats": {
      actionTitle: translateServer({
        defaultMessage: "Record Stats",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: translateServer({
        defaultMessage:
          "Now that you are stats trained, it's time to record stats! Record stats for a game by creating a new game or scrimmage and then clicking the big green button within 1 hour of game time.",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    },
    "record-more-stats": {
      actionTitle: translateServer({
        defaultMessage: "Keep Recording Stats",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: translateServer({
        defaultMessage:
          "Now that your team has recorded stats for a game, keep the momentum going and record stats for every game!",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    },
    "get-trained": {
      actionTitle: translateServer({
        defaultMessage: "Get Stats Trained",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: translateServer({
        defaultMessage:
          "You can get fully stats trained in less than 10 minutes! Head to the Stats tab in the app and complete the tutorial. You can start with the Beginner tutorial, but you will need to complete the Advanced tutorial to become officially stats trained.",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    },
    "encourage-stat-takers": {
      actionTitle: translateServer({
        defaultMessage: "Encourage Stat Takers",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: translateServer({
        defaultMessage:
          "Now that you have people trained to take stats and have given them permission, encourage them to take stats on your next game or scrimmage!",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    },
    "encourage-stat-takers-more": {
      actionTitle: translateServer({
        defaultMessage:
          "Now that your stat takers have recorded stats on a game, keep encouraging them to record stats on every game!",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: "Body",
      actionButtonText: "",
      actionButtonURL: ""
    },
    "encourage-coach-to-invite-stat-takers": {
      actionTitle: translateServer({
        defaultMessage: "Encourage Your Coach to Invite Stats Takers",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionBody: translateServer({
        defaultMessage:
          "Looks like your team doesn't have any stats takers yet. Encourage your coach or team admins to invite people to learn to record stats.",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    },
    "all-done": {
      actionTitle: translateServer({ defaultMessage: "Keep It Up", serverLocale: accountPrivate.communicationLocale || "en-US" }),
      actionBody: translateServer({
        defaultMessage: "Your team is doing great! Keep recording stats to get the most out of Ollie.",
        serverLocale: accountPrivate.communicationLocale || "en-US"
      }),
      actionButtonText: "",
      actionButtonURL: ""
    }
  };

  const templateIdMap = {
    1: "d-d651305d4e2b435397e70ff433dd4da4",
    2: "d-932c3c0bf58a4d0d961a4dd6e505dd5f",
    3: "d-1ef404c9e5f44271b8ab839b23f4e5c2",
    4: "d-0f341c07d37a41c4a393e18e1ef23c68"
  };

  await emailTemplateServiceFn({
    to: account.email,
    templateData: {
      account,
      nextStepInfo: nextStepMap[nextStep]
    },
    templateId: templateIdMap[p.data.sequenceNumber],
    asm: 15345
  });
  // SERVER_ONLY_TOGGLE
}

miscEmails__server__sendOnboardingInteraction.auth = async (req: Request) => {
  if (req.get("x-ph-signature")) {
    //TODO: Verify signature...
  } else {
    //TODO: Throw if not posthook most likely
  }
};

// i18n certified - complete
