import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import moment from "moment";
import { ObjectKeys } from "../../utils";
import _ from "lodash";
import { LICENSE_TYPES } from "@ollie-sports/models";
import { dateFormatters } from "@ollie-sports/i18n";
import { ServerThisContext } from "@ollie-sports/react-bifrost";

export async function miscEmails__server__maybeSendLicenseExpirationWarningEmail(p: { licenseId: string }): Promise<boolean[]> {
  // SERVER_ONLY_TOGGLE
  const { emailTemplateServiceFn, serverConfig } = getServerHelpers();
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const license = await h.License.getDoc(p.licenseId);

  if (
    !license ||
    license.status !== "active" ||
    license.type !== LICENSE_TYPES.team ||
    license.teamLicenseType !== "stripe" ||
    license.expireAtMS > moment().add(31, "days").valueOf()
  ) {
    return [];
  }

  const team = await h.Team.getDoc(license.teamId);

  if (!team) {
    return [];
  }

  const now = Date.now();

  // Failsafe to not accidentally send twice. Make sure at least 5 days have passed since the last email sent to this team
  if (team.lastLicenseExpirationWarningSentMS && now < moment(team.lastLicenseExpirationWarningSentMS).add(5, "days").valueOf()) {
    return [];
  }

  const staffAccountIds = ObjectKeys(team.accounts).filter(aid => !!team.accounts[aid]?.staffTitle);
  const accounts = _.compact(
    await Promise.all(
      staffAccountIds.map(async aid => {
        return await h.Account.getDoc(aid);
      })
    )
  );

  const accountPrivates = _.compact(
    await Promise.all(
      staffAccountIds.map(async aid => {
        return await h.AccountPrivate.getDoc(aid);
      })
    )
  );

  const teamBillingUrl = `${serverConfig.httpWebappRoot}/payment/team/${team.id}`;

  const results = await Promise.all([
    ...accounts.map(async account => {
      const accountPrivate = accountPrivates.find(ap => ap.id === account.id);
      const expireString = dateFormatters.mmmm_d_yyyy(license.expireAtMS, accountPrivate?.communicationLocale ?? "en-us");
      return await new Promise(async resolve => {
        try {
          const statusCode = await emailTemplateServiceFn({
            to: account.email,
            templateId: "d-ae8a9e9eb6f04a64b9db604177f0079c",
            templateData: {
              name: account.firstName,
              date: expireString,
              url: teamBillingUrl,
              teamName: team.name
            }
          });
          if (statusCode > 299) {
            resolve(false);
          }
        } catch (e) {
          console.error(e);
          resolve(false);
        }
        resolve(true);
      });
    }),
    ...[
      await h.Team.setPath({
        id: team.id,
        pathObj: { lastLicenseExpirationWarningSentMS: true },
        value: { lastLicenseExpirationWarningSentMS: now }
      })
    ]
  ]);

  return results as boolean[];
  // SERVER_ONLY_TOGGLE
}

miscEmails__server__maybeSendLicenseExpirationWarningEmail.auth = () => {};
// i18n certified - complete
