import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import moment from "moment";
import { ObjectKeys } from "../../utils";
import _ from "lodash";
import { LICENSE_TYPES } from "@ollie-sports/models";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { dateFormatters } from "@ollie-sports/i18n";

export async function miscEmails__server__maybeSendTeamLicenseExpirationEmail(p: { licenseId: string }): Promise<boolean[]> {
  // SERVER_ONLY_TOGGLE
  const { emailTemplateServiceFn, serverConfig } = getServerHelpers();
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const license = await h.License.getDoc(p.licenseId);

  if (!license || license.status === "active" || license.type !== LICENSE_TYPES.team || license.teamLicenseType !== "stripe") {
    return [];
  }

  const team = await h.Team.getDoc(license.teamId);

  if (!team) {
    return [];
  }

  const now = Date.now();

  // Failsafe to not accidentally send twice. Make sure at least 30 days have passed since the last email sent to this team
  if (team.lastLicenseExpirationSentMS && now < moment(team.lastLicenseExpirationSentMS).add(30, "days").valueOf()) {
    return [];
  }

  const staffAccountIds = ObjectKeys(team.accounts).filter(aid => !!team.accounts[aid]?.staffTitle);
  const accounts = _.compact(
    await Promise.all(
      staffAccountIds.map(async aid => {
        return await h.Account.getDoc(aid);
      })
    )
  );

  const accountPrivates = _.compact(
    await Promise.all(
      staffAccountIds.map(async aid => {
        return await h.AccountPrivate.getDoc(aid);
      })
    )
  );

  const teamBillingUrl = `${serverConfig.httpWebappRoot}/payment/team/${team.id}`;

  const results = await Promise.all([
    ...accounts.map(async account => {
      const accountPrivate = accountPrivates.find(ap => ap.id === account.id);
      const expireString = dateFormatters.mmmm_d_yyyy(license.expireAtMS, accountPrivate?.communicationLocale ?? "en-us");
      return await new Promise(async resolve => {
        try {
          const statusCode = await emailTemplateServiceFn({
            to: account.email,
            templateId: "d-00adf77409b14b9d999cedc2ab9eb5b9",
            templateData: {
              name: account.firstName,
              date: expireString,
              url: teamBillingUrl,
              teamName: team.name
            }
          });
          if (statusCode > 299) {
            resolve(false);
          }
        } catch (e) {
          console.error(e);
          resolve(false);
        }
        resolve(true);
      });
    }),
    ...[
      await h.Team.update({
        id: team.id,
        doc: { lastLicenseExpirationWarningSentMS: now }
      })
    ]
  ]);

  return results as boolean[];
  // SERVER_ONLY_TOGGLE
}
// i18n certified - complete
