import { OptionalQuery } from "@ollie-sports/firebase-lift";
import {
  AccountId,
  CalendarEntry,
  CALENDAR_ENTRY_TYPES,
  PlayerId,
  PrettyPlayer,
  Team,
  Team__StaffTypes
} from "@ollie-sports/models";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import _ from "lodash";
import moment from "moment-timezone";
import { format } from "path";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants/Time";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { formatPercent, getAttendanceReportExportString, ObjectKeys, ObjectValues, shouldSendEmail } from "../../utils";

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function miscEmails__server__emailAttendanceReportToSelf(
  this: ServerThisContext,
  p: {
    team: Team;
    prettyPlayers: PrettyPlayer[];
    selfAccountId: AccountId;
    email: string;
    startDateMS: number;
    endDateMS: number;
    squad?: "a" | "b" | "c";
    eventType?: CALENDAR_ENTRY_TYPES;
  }
): Promise<"success" | "noResults" | "error"> {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const {
    getAppPgPool,
    injectedServerLibraries: { sendGrid }
  } = getServerHelpers();

  if (!p.prettyPlayers.length) {
    return "noResults";
  }

  const coachAccountIds = ObjectKeys(p.team.accounts).filter(
    accountId =>
      p.team.accounts[accountId]?.staffTitle === Team__StaffTypes.assistantCoach ||
      p.team.accounts[accountId]?.staffTitle === Team__StaffTypes.headCoach
  );

  const coachAccounts = _.compact(await h.Account.getDocs(coachAccountIds));

  const q1 = p.team.id;
  const q2 = moment(p.startDateMS).format(MOMENT_DATE_TIME_FORMAT);
  const q3 = moment(p.endDateMS).format(MOMENT_DATE_TIME_FORMAT);
  const q4 = p.eventType ? [p.eventType] : ObjectKeys(CALENDAR_ENTRY_TYPES);
  const queryParams = [q1, q2, q3, q4];
  if (p.squad) {
    const q5 = p.squad;
    queryParams.push(q5);
  }

  const query = `select item
                  from mirror_calendarentry
                  where item ->> 'teamId' = $1
                    and item->>'startDateTime' >= $2
                    and item->>'startDateTime' <= $3
                    and item->>'calendarEntryType'  = any ($4::text[])
                    ${p.squad ? `and (item->>'squad' = $5 or item->>'squad' is null)` : ""}
                    order by item->>'startDateTime' asc;`;

  let calendarEntries: CalendarEntry[] = [];

  try {
    const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

    if (!r1.rowCount) {
      return "noResults";
    }
    calendarEntries = r1.rows.map(r => r.item) as CalendarEntry[];
  } catch (e) {
    return "error";
  }
  let playerCvsString = getAttendanceReportExportString({
    calendarEntries,
    type: "player",
    playerOrCoachInfo: p.prettyPlayers.map(pp => ({
      id: pp.player.id,
      name: `${pp.derived.accountInfo.firstName}${
        pp.derived.accountInfo.lastName.length > 0 ? ` ${pp.derived.accountInfo.lastName[0]}.` : ""
      }`
    })),
    team: p.team,
    eventType: p.eventType,
    squad: p.squad,
    startDateMS: p.startDateMS,
    endDateMS: p.endDateMS,
    locale: this.locale
  });

  let coachCvsString = coachAccounts.length
    ? getAttendanceReportExportString({
        calendarEntries,
        type: "coach",
        playerOrCoachInfo: coachAccounts.map(account => ({
          id: account.id,
          name: `${account.firstName}${account.lastName.length > 0 ? ` ${account.lastName[0]}.` : ""}`
        })),
        team: p.team,
        eventType: p.eventType,
        squad: p.squad,
        startDateMS: p.startDateMS,
        endDateMS: p.endDateMS,
        locale: this.locale
      })
    : "";

  const subject = `${this.translate(
    { defaultMessage: "{teamName} Attendance Reports" },
    { teamName: p.team.name }
  )} - ${this.dateFormatters.mm_dd_yyyy(p.startDateMS)} to ${this.dateFormatters.mm_dd_yyyy(p.endDateMS)}`;
  const attachments = [
    {
      content: Buffer.from(playerCvsString).toString("base64"),
      filename: `${this.translate({ defaultMessage: "Player Attendance Reports" })} - ${this.dateFormatters.mm_dd_yyyy(
        p.startDateMS
      )} to ${this.dateFormatters.mm_dd_yyyy(p.endDateMS)}.csv`,
      type: "application/csv",
      disposition: "attachment",
      contentId: "players"
    }
  ];
  if (coachCvsString.length) {
    attachments.push({
      content: Buffer.from(coachCvsString).toString("base64"),
      filename: `${this.translate({ defaultMessage: "Coach Attendance Reports" })} - ${this.dateFormatters.mm_dd_yyyy(
        p.startDateMS
      )} to ${this.dateFormatters.mm_dd_yyyy(p.endDateMS)}.csv`,
      type: "application/csv",
      disposition: "attachment",
      contentId: "coaches"
    });
  }
  try {
    if (shouldSendEmail(p.email)) {
      await sendGrid.send({
        from: "Ollie Sports <noreply@olliesports>",
        to: p.email,
        subject,
        ipPoolName: "Transactional",
        html: `
          <!DOCTYPE html>
          <html>
          <head>
          <meta charset="utf-8">
          <title>CSV Export</title>
          <meta name="description" content="${subject}">
          <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
          </head>
          <body>
          <p>Attached to this email you will find your attendance report.</p>

          </body>
          </html>
          `,
        attachments
      });
    } else {
      console.log(
        `Not sending email because ${p.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
      );
    }
  } catch (e) {
    return "error";
  }

  return "success";

  // SERVER_ONLY_TOGGLE
}

miscEmails__server__emailAttendanceReportToSelf.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};
// i18n certified - complete
