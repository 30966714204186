import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { getServerHelpers } from "../../helpers";
import { Account, AccountId, PlayerBundle__AccountType, PrettyPlayer, Team } from "@ollie-sports/models";
import { ObjectKeys, shouldSendEmail } from "../../utils";
import _ from "lodash";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { translate } from "@ollie-sports/i18n";

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function miscEmails__server__emailRosterCSVToSelf(p: {
  selfAccountId: string;
  email: string;
  team: Team;
  prettyPlayers: PrettyPlayer[];
}) {
  // SERVER_ONLY_TOGGLE
  const showSquads = !!p.team.enabledFeatures?.squads && !!p.team.squads;
  const {
    injectedServerLibraries: { sendGrid },
    appOllieFirestoreV2: h
  } = getServerHelpers();
  const accountPrivate = await h.AccountPrivate.getDoc(p.selfAccountId);

  const playerBundles = _.compact(
    await h.PlayerBundle.getDocs(_.compact(p.prettyPlayers.map(pp => pp.player.linkedPlayerBundleId)))
  );

  let accountIds: AccountId[] = [];
  playerBundles.forEach(pb => (accountIds = [...accountIds, ...ObjectKeys(pb.managingAccounts ?? {})]));
  const accounts = _.uniqBy(_.compact(await h.Account.getDocs(accountIds)), a => a.id);

  const locale = accountPrivate?.communicationLocale ?? "en-us";
  const subject = translate({ defaultMessage: `{teamName} Roster`, serverLocale: locale }, { teamName: p.team.name });
  let exportString = `${translate.common(locale).FirstName},${translate.common(locale).LastName},${
    translate.common(locale).JerseyNumber
  },${showSquads ? `${translate.common(locale).Squads},` : ""}${[1, 2, 3, 4]
    .map(i => {
      return `${translate(
        {
          defaultMessage: "Account {number} First Name",
          serverLocale: locale
        },
        { number: i }
      )},${translate({ defaultMessage: "Account {number} Last Name", serverLocale: locale }, { number: i })},${translate(
        {
          defaultMessage: "Account {number} Email",
          serverLocale: locale
        },
        { number: i }
      )},${translate({ defaultMessage: "Account {number} Phone Number", serverLocale: locale }, { number: i })}`;
    })
    .join(",")}\n`;
  _.orderBy(p.prettyPlayers, ["firstName", "lastName"]).forEach(player => {
    const assignedSquads = Object.keys(p.team.squadsPlayerMapping?.[player.player.id] ?? {}).filter(
      squad => !!p.team.squadsPlayerMapping?.[player.player.id]?.[squad as "a" | "b" | "c"]
    );
    const playerBundle = player.player.linkedPlayerBundleId
      ? playerBundles.find(pb => pb.id === player.player.linkedPlayerBundleId)
      : undefined;
    let bundleAccounts: Account[] = [];
    if (playerBundle) {
      const playerAccount = accounts.find(
        acc =>
          !!playerBundle.managingAccounts?.[acc.id]?.exists &&
          playerBundle.managingAccounts?.[acc.id]?.type === PlayerBundle__AccountType.selfAthlete
      );
      const guardianAccounts = accounts.filter(
        acc =>
          !!playerBundle.managingAccounts?.[acc.id]?.exists &&
          playerBundle.managingAccounts?.[acc.id]?.type === PlayerBundle__AccountType.guardian
      );
      if (playerAccount) {
        bundleAccounts.push(playerAccount);
      }
      if (guardianAccounts.length) {
        bundleAccounts = [...bundleAccounts, ...guardianAccounts];
      }
    }
    exportString += `${player.derived.accountInfo.firstName},${player.derived.accountInfo.lastName},${
      player.player.jerseyNumber
    },${
      showSquads
        ? `${assignedSquads
            .map(squad => {
              if (squad === "a") {
                return "V";
              } else if (squad === "b") {
                return "JV";
              } else if (squad === "c") {
                return "FS";
              }
              return "";
            })
            .join("/")},`
        : ""
    }${bundleAccounts
      .map(acc => {
        return `${acc.firstName},${acc.lastName},${acc.email},${acc.phoneNumber}`;
      })
      .join(",")}\n`.replace(/undefined/g, "");
  });
  if (shouldSendEmail(p.email)) {
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: p.email,
      subject,
      ipPoolName: "Transactional",
      html: `
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8">
    <title>${translate({ defaultMessage: "CSV Export", serverLocale: locale })}</title>
    <meta name="description" content="${subject}">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    </head>
    <body>
    <p>${translate({
      defaultMessage: "Attached to this email you will find your exported team roster",
      serverLocale: locale
    })}.</p>
    </body>
    </html>
    `,
      attachments: [
        {
          content: Buffer.from(exportString).toString("base64"),
          filename: `${p.team.name} Roster.csv`,
          type: "application/csv",
          disposition: "attachment",
          contentId: "roster"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${p.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }
  // SERVER_ONLY_TOGGLE
}

miscEmails__server__emailRosterCSVToSelf.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};
// i18n certified - complete
