import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { AccountId, Team } from "@ollie-sports/models";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { translate } from "@ollie-sports/i18n";

export async function miscEmails__server__howToUpgradeEmail(p: {
  accountId: AccountId;
  teamId?: string;
}): Promise<{ status: "success" } | { status: "retry" }> {
  // SERVER_ONLY_TOGGLE
  const { emailTemplateServiceFn, channels, serverConfig } = getServerHelpers();
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const team = p.teamId ? await h.Team.getDoc(p.teamId) : null;
  const [account, accountPrivate] = await Promise.all([h.Account.getDoc(p.accountId), h.AccountPrivate.getDoc(p.accountId)]);
  const locale = accountPrivate?.communicationLocale ?? "en-us";

  if (!account) {
    return { status: "retry" };
  }

  try {
    const statusCode = await emailTemplateServiceFn({
      to: account.email,
      templateId: "d-272de34b5bb74fd6a76d52b4d2616a92",
      templateData: {
        name: account.firstName,
        teamName: team?.name || translate({ defaultMessage: "Your Team", serverLocale: locale }),
        url: p.teamId
          ? `${serverConfig.httpWebappRoot}/payment/team/${p.teamId}`
          : `${serverConfig.httpWebappRoot}/app/teamBilling`
      }
    });
    if (statusCode > 299) {
      return { status: "retry" };
    }
    await channels.pulseChannel(`Upgrade info requested:
    ${translate.common(locale).Name}: ${account.firstName} ${account.lastName}
    ${translate.common(locale).Email}: ${account.email}
    `);
    olliePipe.emitEvent({ type: "analytic-teamUpgradeRequest", payload: { accountId: account.id } });
  } catch (e) {
    console.error(e);
    return { status: "retry" };
  }

  return { status: "success" };
  // SERVER_ONLY_TOGGLE
}

miscEmails__server__howToUpgradeEmail.auth = () => {};
// i18n certified - complete
