import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { AccountId, Team } from "@ollie-sports/models";

export async function miscEmails__server__paymentReceiptEmail(p: {
  name: string;
  email: string;
  receiptUrl: string;
}): Promise<{ status: "success" } | { status: "retry" }> {
  // SERVER_ONLY_TOGGLE
  const { emailTemplateServiceFn, appFirebaseAdminApp: admin, channels } = getServerHelpers();
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  try {
    const statusCode = await emailTemplateServiceFn({
      to: p.email,
      templateId: "d-ac890d2d4cc342c3820a2dffc383a945",
      templateData: {
        name: p.name,
        url: p.receiptUrl
      }
    });
    if (statusCode > 299) {
      return { status: "retry" };
    }
  } catch (e) {
    console.error(e);
    return { status: "retry" };
  }

  return { status: "success" };
  // SERVER_ONLY_TOGGLE
}

miscEmails__server__paymentReceiptEmail.auth = () => {};
// i18n certified - complete
